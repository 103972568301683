<script setup>
import { defineProps, defineEmits, ref } from 'vue'

import { BAlert } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { getExternalData } from '@/services/main-api/shop/orders/orders'

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  orderId: {
    type: [Number, String],
    default: null,
  },
})

const emits = defineEmits([
  'update:data',
])

const externalDataCard = ref(null)

const errorMessage = ref(null)

const getStats = async () => {
  errorMessage.value = null
  try {
    // loading.value = true
    const res = await getExternalData({
      id: props.orderId,
    })
    emits('update:data', res.data.data)
  } catch (e) {
    errorMessage.value = 'Ошибка получения данных, попробуйте позже'
  } finally {
    externalDataCard.value.showLoading = false
  }
}
</script>

<template>
  <b-card-actions
    ref="externalDataCard"
    title="Внешние данные"
    action-collapse
    collapsed
    action-refresh
    @refresh="getStats"
  >
    <b-alert
      v-if="errorMessage"
      variant="danger"
      show
      class="mb-0"
    >
      <div class="alert-body">
        {{ errorMessage }}
      </div>
    </b-alert>

    <div v-else-if="data">
      <vue-json-pretty
        :data="data"
      />
    </div>

    <b-alert
      v-else
      variant="info"
      show
      class="mb-0"
    >
      <div class="alert-body">
        Нет данных. Нажмите обновить
      </div>
    </b-alert>

    <slot />

  </b-card-actions>
</template>

<style scoped lang="scss">

</style>
