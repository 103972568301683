<template>
  <b-overlay
    spinner-variant="secondary"
    variant="white"
    class="order-item"
  >
    <div class="row no-gutters">
      <!--      <div class="col-auto mr-1">-->
      <!--        <div-->
      <!--          class="order-item__image-container"-->
      <!--        >-->
      <!--          <img-->
      <!--            v-if="image"-->
      <!--            :src="image"-->
      <!--            :alt="name"-->
      <!--          >-->
      <!--          <feather-icon-->
      <!--            v-else-->
      <!--            icon="ImageIcon"-->
      <!--            class="order-item__img-placeholder"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col mr-1">
        <div class="order-item__name">
          {{ name }}
        </div>
        <div class="order-item__prices">
          <div class="order-item__prices__now">
            {{ price.toLocaleString() }} ₽
          </div>
        </div>
        <div class="order-item__count">
          <div class="">
            Склад Merlion
          </div>
        </div>
      </div>
      <div class="col-auto">
        <b-form-spinbutton
          :value="item.count"
          min="1"
          size="sm"
          disabled
        />
        <div class="order-item__total">
          {{ (item.count * price).toLocaleString() }} ₽
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BFormSpinbutton,
  BOverlay,
} from 'bootstrap-vue'
import get from 'lodash/get'

export default {
  name: 'OrderItem',
  setup() {
    return {
    }
  },
  components: {
    BFormSpinbutton,
    BOverlay,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return get(this.item, 'offerName', '')
    },
    // image() {
    //   return get(this.item, 'product.images[0].path', null)
    // },
    price() {
      return Number(get(this.item, 'price', '')) + Number(get(this.item, 'subsidy', ''))
    },
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';
.order-item{
  padding: 0 0 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $light
;

  &:last-child{
    margin-bottom: 0;
    border-bottom: 0;
  }

  &__image-container{
    display: block;
    height: 0;
    width: 60px;
    padding-bottom: 60px;
    position: relative;
    background: #e2e2e2;
    border-radius: 6px;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__img-placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #6E6B7B;
    width: 40%;
    height: 40%;
  }
  &__name{
    color: $headings-color;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  &__prices{
    &__now{
      color: $headings-color;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
    &__old{
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-decoration-line: line-through;

      color: $gray-100;
    }
  }

  &__total{
    text-align: right;
    color: $headings-color;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-top: 10px;
  }

  &__count{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    div:nth-child(1){
      color: $secondary;
      margin-right: 5px;
    }
    div:nth-child(2),div:nth-child(3){
      font-weight: 400;
    }
  }
}
</style>
