<template>
  <component
    :is="formComponent"
    :id="id"
  />
</template>

<script>
import camelCase from 'lodash/camelCase'
import DefaultForm from '../components/default/forms/ShowForm.vue'
import YandexMarketFbsForm from '../components/yandex-market-fbs/forms/ShowForm.vue'
import YandexMarketDbsForm from '../components/yandex-market-dbs/forms/ShowForm.vue'
import MerlionFreshYmfbsForm from '../components/merlion-fresh-ymfbs/forms/ShowForm.vue'
import MerlionYmdbsForm from '../components/merlion-ymdbs/forms/ShowForm.vue'

export default {
  name: 'ShowItemView',
  components: {
    DefaultForm,
    YandexMarketFbsForm,
    YandexMarketDbsForm,
    MerlionFreshYmfbsForm,
    MerlionYmdbsForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    let formComponent = 'DefaultForm'

    if (props.type !== 'website_external' && props.type !== 'website_store') {
      formComponent = camelCase(`${props.type}_form`)
    }

    return {
      formComponent,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
