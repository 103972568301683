import * as Validator from 'validatorjs'
import {
  reactive, toRefs,
} from 'vue'
import { get } from 'lodash'

export default function useValidation({
  rules = {},
  customErrorMessages = {}, // https://github.com/mikeerickson/validatorjs
} = {}) {
  const data = reactive({
    validation: {},
    error_message: '',
  })

  const clearValidation = () => {
    data.validation = {}
    data.error_message = ''
  }

  const checkValidationItem = item => {
    clearValidation()
    let hasError = false

    if (Object.keys(rules).length) {
      const dataValidator = new Validator(item, rules, customErrorMessages)
      if (dataValidator.fails()) {
        hasError = true
        data.validation = dataValidator.errors.all()
      }
    }
    return !hasError
  }

  const catchValidation = e => {
    data.validation = get(e.response, 'data.errors')
    data.error_message = get(e.response, 'data.error', '')
  }

  return {
    ...toRefs(data),
    clearValidation,
    catchValidation,
    checkValidationItem,
  }
}
