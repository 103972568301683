<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <app-breadcrumb
      :breadcrumb="breadcrumb"
    />
    <b-row class="order-page">
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
        >
          <b-card-body class="py-1">
            <b-row align-v="center">
              <b-col>
                <h3 class="mb-0 p-0">
                  {{ pageTitle }}
                </h3>
              </b-col>
              <b-col
                cols="auto"
                class="ml-auto"
              >
                <b-badge variant="primary">
                  {{ get(status, 'name') }}
                </b-badge>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card
          title="Позиции заказа"
        >
          <div class="order-page__items">
            <order-item
              v-for="(orderItem) in order.order_product_items"
              :key="'oi' + orderItem.id"
              :editing="isManageOrderItems"
              :item="orderItem"
              class="order-page__item"
              @setCount="setCountOrderProductItem"
              @deleteItem="deleteOrderProductItem"
            />
          </div>
          <div
            v-if="isManageOrderItems"
            class="o-search"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="search-order-items"
                v-model.trim="querySearchItems"
                placeholder="Поиск товара"
              />

            </b-input-group>
            <div
              v-if="searchResultItems.length"
              class="o-search__results-container"
            >
              <div class="o-search__results">
                <ul>
                  <li
                    v-for="(searchItem, indexSearchItem) in searchResultItems"
                    :key="'si' + indexSearchItem"
                    class="o-search__result__item"
                  >
                    <offer-search-item
                      :item="searchItem"
                      @add="addProductItem"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-card>
        <b-card
          title="Итог"
          no-body
        >
          <b-card-body class="pb-0 mb-0">
            <b-card-title>
              Итог
            </b-card-title>
          </b-card-body>
          <order-resume
            :order="order"
          />
        </b-card>
        <template v-if="order && order.source === 'yandex_market_dbs' && source">
          <b-card
            title="Источник"
          >
            <b-link :to="{ name: 'integrations.show', params: { id: source.id } }">
              {{ source.name }}
            </b-link>
            <div class="small text-monospace">
              {{ source.humanType }}
            </div>
          </b-card>
        </template>
        <b-card>
          <b-form-group
            label="Комментарий от покупателя"
            label-for="notes"
          >
            <b-form-textarea
              id="notes"
              :value="order.comment"
              placeholder="Комментарий пуст"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Комментарий (виден только вам)"
            label-for="notes"
            class="mb-0"
          >
            <b-form-textarea
              id="notes"
              v-model.trim="order.notes"
              placeholder="Введите комментарий"
              :state="get(validation, 'notes[0]') ? false : null"
            />
            <small
              v-if="!!get(validation, 'notes[0]')"
              class="text-danger"
            >
              {{ get(validation, 'notes[0]') }}
            </small>
          </b-form-group>
        </b-card>

        <external-order-stats
          :stats.sync="order.external_stats"
          :order-id="order.id"
        />

        <external-data
          :order-id="order.id"
          :data.sync="order.external_data"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card
          v-if="get(availableStatuses, 'length', 0)"
          title="Изменить статус"
        >
          <b-button
            v-for="(availableStatus, indexAvailableStatus) in availableStatuses"
            :key="'as' + availableStatus.id"
            variant="outline-primary"
            :class="{'mb-75': indexAvailableStatus < availableStatuses.length - 1}"
            block
            @click="goToStatus(availableStatus)"
          >
            {{ availableStatus.name }}
          </b-button>
        </b-card>
        <b-card
          title="История изменений"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(statusHistoryItem, indexStatusHistoryItem) in get(order, 'status_history', [])"
              :key="'sh' + indexStatusHistoryItem"
              :title="get(getStatusByCode(statusHistoryItem.new_status), 'name', '')"
              subtitle="Установлен статус заказа"
              :time="statusHistoryItem.updated_at ? statusHistoryItem.updated_at : statusHistoryItem.date"
              :variant="getVariantByCode(statusHistoryItem.new_status)"
            />
          </app-timeline>
        </b-card>
        <b-card v-if="order.is_test">
          <b-button
            variant="outline-danger"
            block
            @click="deleteTestOrder(order)"
          >
            Удалить
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  BRow,
  BFormRow,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BFormSpinbutton,
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BTbody,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import debounce from 'lodash/debounce'
import moment from 'moment'
import get from 'lodash/get'
import {
  computed, onMounted, reactive, ref, watch, getCurrentInstance,
} from 'vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VueJsonPretty from 'vue-json-pretty'
import {
  getOrder as getOrderApi, updateOrder as updateOrderApi, deleteOrder as deleteOrderApi,
} from '@/services/main-api/shop/orders/orders'
import { getStatuses as getStatusesApi } from '@/services/main-api/shop/statuses'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import { getProducts } from '@/services/main-api/catalog/products/products'
import { updateProductItem, createProductItem, deleteProductItem } from '@/services/main-api/shop/orders/product-items'
import OrderItem from '@/modules/orders/components/OrderItem.vue'
import useWarehouseList from '@/modules/orders/composables/useWarehouseList'
import {
  getYandexMarketDbsIntegration,
} from '@/services/main-api/integrations/yandex-market-dbs'
import OrderResume from '../../OrderResume.vue'
import OfferSearchItem from '../../OfferSearchItem.vue'
import 'vue-json-pretty/lib/styles.css'
import ExternalOrderStats from '../../ExternalOrderStats.vue'
import ExternalData from '../../ExternalData.vue'
import useDeleieItem from '@/composables/useDeleieItem'

export default {
  name: 'ShowOrderView',
  components: {
    OrderResume,
    OrderItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormTextarea,
    BCardTitle,
    AppBreadcrumb,
    BCardBody,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    OfferSearchItem,
    BLink,
    BFormSpinbutton,
    BFormRow,
    BCardActions,
    VueJsonPretty,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BTbody,
    BAlert,
    ExternalOrderStats,
    ExternalData,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy

    // BEGIN Base
    const loading = ref(false)
    const errorMessage = ref('')
    const validation = reactive({})
    // END Base

    moment.locale('ru')

    // BEGIN Source
    const source = ref(null)

    const getSource = async ({
      id,
    }) => {
      try {
        const res = await getYandexMarketDbsIntegration({ id })
        source.value = {
          ...res.data.data,
          type: 'yandex_market_dbs',
          humanType: 'ЯндексМаркет DBS',
        }
      } catch (e) {
        source.value = null
      }
    }
    // END Source

    // BEGIN Заказ
    const order = ref({
    })

    const getOrder = async () => {
      try {
        const res = await getOrderApi({
          id: props.id,
          includes: 'order_product_items;order_product_items.product;order_product_items.product.images;order_product_items.product_warehouse_remain',
        })
        order.value = res.data.data

        await Promise.all([
          getSource({
            id: res.data.data.source_id,
          }),
        ])
      } catch (e) {
        // ...
      }
    }
    // Notest

    const updateNote = debounce(async () => {
      try {
        await updateOrderApi({
          id: order.value.id,
          notes: order.value.notes,
        })
      } catch (e) {
        // ...
      }
    }, 500)

    // Person
    const updatePerson = async () => {
      try {
        await updateOrderApi({
          id: order.value.id,
          person_id: order.value.person_id,
        })
      } catch (e) {
        // ...
      }
    }
    // END Заказ

    // BEGIN Статусы
    const statuses = ref([])
    const getStatuses = async () => {
      try {
        const res = await getStatusesApi({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 100,
          orderBy: 'sort_order',
          sortedBy: 'asc',
        })

        statuses.value = res.data.data
      } catch (e) {
        statuses.value = []
      }
    }
    const getStatusById = id => statuses.value.find(s => s.id === id)
    const getStatusByCode = code => statuses.value.find(s => s.code === code)
    const getVariantByCode = code => {
      let variant = 'success'
      // success
      // info
      // warning
      // primary
      // secondary
      switch (code) {
        case 'delivery':
          variant = 'info'
          break
        case 'finished':
          variant = 'secondary'
          break
        default:
          variant = 'success'
      }
      return variant
    }

    const status = computed(() => getStatusById(order.value.status_id))

    // Доступные для изменения статусы
    const availableStatuses = computed(() => {
      let items = []

      if (status.value) {
        items = status.value.available_statuses_ymdbs.map(sa => statuses.value.find(i => i.code === sa))
      }

      return items
    })

    const goToStatus = async newStatus => {
      loading.value = true
      try {
        const res = await updateOrderApi({
          id: order.value.id,
          status_id: newStatus.id,
        })
        order.value.status_id = res.data.data.status_id
        order.value.status_history = res.data.data.status_history
      } catch (e) {
        console.log(e)
      } finally {
        loading.value = false
      }
    }
    // END Статусы

    // BEGIN OrderProductItems
    const querySearchItems = ref('')
    const searchResultItems = ref([])

    async function getOffers() {
      if (!querySearchItems.value) {
        searchResultItems.value = []
        return
      }

      try {
        const res = await getProducts({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
          search: `is_active:true;name:${querySearchItems.value}`,
          searchJoin: 'and',
          includes: 'images;product_offers;product_offers.product_warehouse_remains',
          mode: 'in_stock_by_offer',
        })

        const resItems = []

        if (res.data.data && Array.isArray(res.data.data)) {
          const hasItems = get(order.value, 'order_product_items', [])
          res.data.data.forEach(p => {
            p.product_offers.forEach(o => {
              resItems.push({
                ...o,
                product_warehouse_remains: o.product_warehouse_remains.filter(pwr => {
                  if ((pwr.total - pwr.reserve) <= 0) {
                    return false
                  }

                  const hasItem = hasItems.find(hi => hi.product_id === o.product_id && hi.product_offer_id === o.id && hi.product_warehouse_remain_id === pwr.id)
                  if (hasItem) {
                    return false
                  }

                  return true
                }),
                product: {
                  code: p.code,
                  name: p.name,
                  image: get(p, 'images[0].path', null),
                },
              })
            })
          })
        }

        searchResultItems.value = resItems.filter(ri => !!ri.product_warehouse_remains.length)
      } catch (e) {
        searchResultItems.value = []
      }
    }

    // Отложенный поиск
    const debouncedSearch = debounce(async () => {
      await getOffers()
    }, 500)

    // Запуск отложенного поиска при изменении querySearchItems
    watch(querySearchItems, () => {
      debouncedSearch()
    })

    const addProductItem = async remain => {
      await createProductItem({
        order_id: order.value.id,
        product_offer_id: remain.product_offer_id,
        product_warehouse_remain_id: remain.id,
        count: 1,
      })

      loading.value = true
      searchResultItems.value = []
      querySearchItems.value = ''
      await getOrder()

      loading.value = false
    }

    async function setCountOrderProductItem({ item, count }) {
      instance.$set(item, 'editable', true)
      try {
        await updateProductItem({
          id: item.id,
          count,
        })

        await getOrder()
      } catch (e) {
        instance.$toast({
          component: ToastificationContent,
          props: {
            title: 'Внимание!',
            icon: 'BellIcon',
            text: 'Недопустимое количество!',
            variant: 'danger',
          },
        })
        const old = item.count

        item.count = count
        await instance.$nextTick()
        item.count = old
      } finally {
        instance.$set(item, 'editable', false)
      }
    }

    async function deleteOrderProductItem(item) {
      instance.$set(item, 'editable', true)
      await deleteProductItem(item.id)
      // await getOrder()
      const index = order.value.order_product_items.findIndex(i => i.id === item.id)
      order.value.order_product_items.splice(index, 1)
    }

    const isManageOrderItems = computed(() => {
      if (order.value.source === 'yandex_market_dbs') {
        return false
      }
      return get(status.value, 'manage_order_items', false)
    })
    // END OrderProductItems

    // BEGIN Meta
    const pageTitle = computed(() => `Заказ ${get(order.value, 'number')}${get(order.value, 'is_test') ? ' (тестовый)' : ''}`)
    const breadcrumb = computed(() => [
      {
        text: 'Заказы',
        active: false,
        to: {
          name: 'orders',
        },
      },
      {
        text: pageTitle.value,
        active: true,
      },
    ])
    // END Meta

    // BEGIN Warehouses
    const {
      getItems: getWarehouses,
      getWarehouseNameByID,
      items: warehouses,
    } = useWarehouseList()
    // END Warehouses

    // BEGIN Delete Test Order
    const { deleteItem: deleteTestOrder } = useDeleieItem({
      deleteHandler: async item => {
        await deleteOrderApi(item.id)
      },
      getQuestionTextBeforeDeletion: item => `Заказ ${item.id} будет удален`,
      getTextAfterDeletion: () => 'Заказ был удален.',
      runAfterDeletion: async () => {
        await instance.$router.push({
          name: 'orders',
        })
      },
    })
    // END Delete Test Order

    // BEGIN Main
    const getData = async () => {
      await Promise.all([
        getStatuses(),
        getOrder(),
      ])
    }

    onMounted(async () => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
        return
      }

      loading.value = true
      await getData()
      getWarehouses()
      loading.value = false

      // Notest
      watch(() => order.value.notes, () => {
        updateNote()
      })

      // Person
      watch(() => order.value.person_id, () => {
        updatePerson()
      })
    })
    // END Main

    return {
      // Base
      loading,
      errorMessage,
      validation,

      // Order
      order,
      deleteTestOrder,

      // Source
      source,

      // Статусы
      statuses,
      getStatusById,
      getStatusByCode,
      getVariantByCode,
      status,
      availableStatuses,
      goToStatus,
      isManageOrderItems,

      // OrderProductItems
      querySearchItems,
      searchResultItems,
      addProductItem,
      setCountOrderProductItem,
      deleteOrderProductItem,

      // Meta
      pageTitle,
      breadcrumb,

      // Warehouses
      getWarehouseNameByID,
      warehouses,

      // Others
      get,
      moment,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
// Core variables and mixins
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.o-search{
  &__results-container{
    position: relative;
    z-index: 10;
  }

  &__results{
    position: absolute;
    background-color: $white;
    width: 100%;
    margin-top: 5px;
    overflow-y: auto;
    border-radius: 0.5rem;
    max-height: 40vh;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        // cursor: pointer;
        list-style: none;
        padding: 0.75rem 1rem;
        &:hover{
          // background: $gray-200;
        }
      }
    }

    body.dark-layout &{
      background-color: $theme-dark-input-bg;
      color: $theme-dark-body-color;

      ul {
        li {
          color: $theme-dark-body-color;
          &:hover{
            // background-color: $theme-dark-table-hover-bg;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
// Core variables and mixins
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.order-page{
  &__items{
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__item{
    // margin-bottom: 15px;
  }
}
</style>
