<template>
  <div class="order-resume">
    <b-table-simple
      :striped="true"
      :bordered="false"
      :borderless="true"
      :outlined="false"
      :small="false"
      :hover="false"
      :dark="false"
      :fixed="false"
      :responsive="true"
      class="mb-0"
    >
      <b-tbody>
        <b-tr>
          <b-th>Товаров</b-th>
          <b-td>{{ count }}</b-td>
        </b-tr>
        <b-tr>
          <b-th><div class="h3">
            Итого:
          </div></b-th>
          <b-td>{{ total.toLocaleString() }} ₽</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTh,
  BTd,
  BTr,
} from 'bootstrap-vue'
import get from 'lodash/get'

export default {
  name: 'OrderResume',
  components: {
    BTableSimple,
    BTbody,
    BTh,
    BTd,
    BTr,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    count() {
      return get(this.order, 'order_product_items', []).reduce((sum, item) => sum + item.count, 0)
    },
    total() {
      return get(this.order, 'order_product_items', []).reduce((sum, item) => sum + (item.price * item.count), 0)
    },
  },
}
</script>

<style scoped lang="scss">
.order-resume{

}
</style>
