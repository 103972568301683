<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { createSourceOrder, updateSourceOrder, deleteSourceOrder } from '@/services/main-api/shop/source-orders'
import { getOrder } from '@/services/main-api/shop/orders/orders'
import get from 'lodash/get'
import {
  BCard, BCol, BFormGroup, BFormInput, BButton,
  BInputGroup, BFormRow,
  BTableSimple, BTbody, BTr, BTh, BTd, BThead,
  BBadge,
} from 'bootstrap-vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

const props = defineProps({
  sourceOrders: {
    type: Array,
    default: () => [],
  },
  orderId: {
    type: [Number, String],
    default: null,
  },
})

const emits = defineEmits([
  'update:sourceOrders',
])

const sourceOrdersCard = ref(null)
const errorMessage = ref(null)

const getSourceOrders = async () => {
  errorMessage.value = null
  try {
    const res = await getOrder({
      id: props.orderId,
      includes: 'source_orders',
    })

    emits('update:sourceOrders', get(res, 'data.data.source_orders', []))
  } catch (e) {
    errorMessage.value = 'Ошибка получения данных, попробуйте позже'
  } finally {
    sourceOrdersCard.value.showLoading = false
  }
}

const orderItemValue = ref({
  type: 'merlion',
  number: '',
})

const validation = ref({})

const clearForm = () => {
  orderItemValue.value.number = ''
  orderItemValue.value.type = 'merlion'
}

const clearValidation = () => {
  validation.value = {}
}

const checkValidation = () => {
  clearValidation()

  let hasError = false

  if (!orderItemValue.value.type) {
    validation.value.type = ['Выберите тип источника']
    hasError = true
  }

  if (!orderItemValue.value.number) {
    validation.value.number = ['Введите номер заказа']
    hasError = true
  }

  return !hasError
}

const isProgressAdd = ref(false)
const addOrder = async () => {
  if (isProgressAdd.value || !checkValidation()) {
    return
  }

  isProgressAdd.value = true
  sourceOrdersCard.value.showLoading = true
  try {
    await createSourceOrder({
      order_id: props.orderId,
      type: orderItemValue.value.type,
      number: orderItemValue.value.number,
    })

    clearForm()
    await getSourceOrders()
  } catch (e) {
    sourceOrdersCard.value.showLoading = false
  } finally {
    isProgressAdd.value = false
  }
}

const deleteOrder = async id => {
  sourceOrdersCard.value.showLoading = true
  try {
    await deleteSourceOrder(id)

    await getSourceOrders()
  } catch (e) {
    sourceOrdersCard.value.showLoading = false
  }
}

const updateSourceOrderNoError = async data => {
  try {
    await updateSourceOrder(data)
  } catch (e) {
    //
  }
}

const updateSourceOrders = async () => {
  const promises = props.sourceOrders.map(i => ({
    id: i.id,
    number: i.number,
  })).map(updateSourceOrderNoError)

  await Promise.all(promises)
  await getSourceOrders()
}
</script>

<template>
  <b-card-actions
    ref="sourceOrdersCard"
    title="Заказы источника"
    action-collapse
    collapsed
    action-refresh
    @refresh="updateSourceOrders"
  >
    <b-form-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Тип источника"
          label-for="source-order-type"
        >
          <b-form-input
            id="source-order-type"
            v-model.trim="orderItemValue.type"
            placeholder="Выберите тип источника"
            :state="get(validation, 'type[0]') ? false : null"
            readonly
            size="sm"
          />

          <small
            v-if="!!get(validation, 'type[0]')"
            class="text-danger"
          >
            {{ get(validation, 'type[0]') }}
          </small>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="ID заказа"
          label-for="source-order-id"
        >
          <b-form-input
            id="source-order-id"
            v-model.trim="orderItemValue.number"
            placeholder="Введите ID заказа"
            :state="get(validation, 'number[0]') ? false : null"
            size="sm"
          />

          <small
            v-if="!!get(validation, 'number[0]')"
            class="text-danger"
          >
            {{ get(validation, 'number[0]') }}
          </small>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-button
      variant="outline-primary"
      size="sm"
      @click="addOrder"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Добавить</span>
    </b-button>

    <h5 class="mt-2">
      Заказы
    </h5>
    <b-table-simple
      :striped="true"
      :bordered="false"
      :borderless="true"
      :outlined="false"
      :small="false"
      :hover="false"
      :dark="false"
      :fixed="false"
      :responsive="true"
      class=""
    >
      <b-thead>
        <b-tr>
          <b-th>Источник</b-th>
          <b-th>ID Заказа</b-th>
          <b-th>Статус</b-th>
          <b-th>Действия</b-th>
        </b-tr>
      </b-thead>
      <b-tbody
        v-if="sourceOrders.length"
      >
        <b-tr
          v-for="itemOrder in sourceOrders"
          :key="'io-' + itemOrder.id"
        >
          <b-td>{{ itemOrder.type }}</b-td>
          <b-td>{{ itemOrder.number }}</b-td>

          <b-td>
            <b-badge
              size="sm"
              :variant="get(itemOrder, 'response_data.order.document_no', null) ? 'success' : 'secondary'"
            >
              {{ get(itemOrder, 'response_data.order.document_no', null) ? 'Получено' : 'Нет данных' }}
            </b-badge>

          </b-td>
          <b-td>
            <b-button
              variant="outline-danger"
              class="btn-icon"
              size="sm"
              @click="deleteOrder(itemOrder.id)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-else
      >
        <b-tr>
          <b-td colspan="4">
            <div class="text-secondary">
              -
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h5 class="mt-2">
      Позиции
    </h5>
    <b-table-simple
      :striped="true"
      :bordered="false"
      :borderless="true"
      :outlined="false"
      :small="false"
      :hover="false"
      :dark="false"
      :fixed="false"
      :responsive="true"
      class=""
    >
      <b-thead>
        <b-tr>
          <b-th>Merlion ID</b-th>
          <b-th>Цена</b-th>
          <b-th>Заказ</b-th>
        </b-tr>
      </b-thead>

      <template
        v-if="sourceOrders.length"
      >
        <b-tbody
          v-for="itemOrder2 in sourceOrders"
          :key="'iso-' + itemOrder2.id"
        >
          <b-tr
            v-for="itemOrderItem in get(itemOrder2, 'response_data.items', [])"
            :key="'iso-' + itemOrderItem.item_no + itemOrder2.id"
            :class="{'d-none': !itemOrderItem.item_no}"
          >
            <b-td>{{ itemOrderItem.item_no }}</b-td>
            <b-td>{{ Number(get(itemOrderItem, 'price', 0)).toLocaleString("ru-RU") }}</b-td>
            <b-td>{{ itemOrderItem.document_no }}</b-td>
          </b-tr>
        </b-tbody>
      </template>
      <b-tbody
        v-else
      >
        <b-tr>
          <b-td colspan="3">
            <div class="text-secondary">
              -
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <vue-json-pretty
      :data="sourceOrders"
    />
  </b-card-actions>
</template>

<style scoped lang="scss">

</style>
