import axiosIns from '@/libs/axios'

export function getProducts({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes, mode,
}) {
  return axiosIns.get('/catalog/products/products', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
      mode,
    },
  })
}

export function getProduct({ id, includes }) {
  return axiosIns.get(`/catalog/products/products/${id}`, {
    params: {
      includes,
    },
  })
}

export function deleteProduct(id) {
  return axiosIns.delete(`/catalog/products/products/${id}`)
}

export function createProduct({
  is_active,
  name,
  slug,
  product_category_id,
  description,
  purchase_price,
  price,
  sale_price,
  seo: {
    title: seoTitle,
    description: seoDescription,
    keywords: seoKeywords,
  },
}) {
  return axiosIns.post('/catalog/products/products', {
    is_active,
    name,
    slug,
    product_category_id,
    description,
    purchase_price,
    price,
    sale_price,
    seo: {
      title: seoTitle,
      description: seoDescription,
      keywords: seoKeywords,
    },
  })
}

export function updateProduct({
  id,
  is_active,
  name,
  slug,
  product_category_id,
  description,
  purchase_price,
  price,
  sale_price,
  seo: {
    title: seoTitle,
    description: seoDescription,
    keywords: seoKeywords,
  },
}) {
  return axiosIns.patch(`/catalog/products/products/${id}`, {
    is_active,
    name,
    slug,
    product_category_id,
    description,
    purchase_price,
    price,
    sale_price,
    seo: {
      title: seoTitle,
      description: seoDescription,
      keywords: seoKeywords,
    },
  })
}

export default {
  getProduct,
  getProducts,
  deleteProduct,
  createProduct,
  updateProduct,
}
