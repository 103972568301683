import axiosIns from '@/libs/axios'

export function getPerson({ id }) {
  return axiosIns.get(`/contacts/persons/${id}`, {
    params: {
    },
  })
}

export function getPersons({
  company, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/contacts/persons', {
    params: {
      company,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createPerson({
  company_id,
  phone,
  notes,
  name,
  surname,
  patronymic,
}) {
  return axiosIns.post('/contacts/persons', {
    company_id,
    phone,
    notes,
    name,
    surname,
    patronymic,
  })
}

export function updatePerson({
  id,
  phone,
  notes,
  name,
  surname,
  patronymic,
}) {
  return axiosIns.patch(`/contacts/persons/${id}`, {
    phone,
    notes,
    name,
    surname,
    patronymic,
  })
}

export function deletePerson(id) {
  return axiosIns.delete(`/contacts/persons/${id}`)
}

export default {
  getPerson,
  getPersons,
  createPerson,
  updatePerson,
  deletePerson,
}
