<template>
  <b-overlay
    :key="'cs' + key"
    :show="loading || personLoading"
    spinner-variant="secondary"
    class="contact-select"
    variant="white"
  >
    <template v-if="showMode === 'show'">
      <b-card
        bg-variant="light"
        class="contact-select__info mb-0"
      >

        <h3
          v-if="get(contact, 'surname') || get(contact, 'name') || get(contact, 'patronymic')"
          class="mb-1"
        >
          {{ get(contact, 'surname') }} {{ get(contact, 'name') }} {{ get(contact, 'patronymic') }}
        </h3>

        <div>
          <strong>Телефон:</strong> {{ get(contact, 'phone') }}
        </div>
        <div
          v-if="get(contact, 'notes')"
          class="mt-1"
        >
          <strong>Комментарий</strong>
          <div style="white-space: pre-line;">
            {{ get(contact, 'notes') }}
          </div>
        </div>
      </b-card>
    </template>
    <b-form-row
      v-else
      class="contact-select__form"
    >
      <b-col cols="12">
        <b-form-group
          label="Телефон *"
          label-for="phone"
        >
          <a-phone-input
            id="phone"
            v-model.trim="contact.phone"
            :state="get(validation, 'phone[0]') ? false : null"
          />
          <small
            v-if="!!get(validation, 'phone[0]')"
            class="text-danger"
          >
            {{ get(validation, 'phone[0]') }}
          </small>
        </b-form-group>
      </b-col>
      <template v-if="!isAlreadyExistsPerson">
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group

            label="Фамилия"
            label-for="contact-surname"
          >
            <b-form-input
              id="contact-surname"
              v-model.trim="contact.surname"
              placeholder="Фамилия"
              :state="get(validation, 'surname[0]') ? false : null"
            />
            <small
              v-if="!!get(validation, 'surname[0]')"
              class="text-danger"
            >
              {{ get(validation, 'surname[0]') }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Имя"
            label-for="contact-name"
          >
            <b-form-input
              id="contact-name"
              v-model.trim="contact.name"
              placeholder="Имя"
              :state="get(validation, 'name[0]') ? false : null"
            />
            <small
              v-if="!!get(validation, 'name[0]')"
              class="text-danger"
            >
              {{ get(validation, 'name[0]') }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Отчество"
            label-for="contact-patronymic"
          >
            <b-form-input
              id="contact-patronymic"
              v-model.trim="contact.patronymic"
              placeholder="Отчество"
              :state="get(validation, 'patronymic[0]') ? false : null"
            />
            <small
              v-if="!!get(validation, 'patronymic[0]')"
              class="text-danger"
            >
              {{ get(validation, 'patronymic[0]') }}
            </small>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Комментарий (о контакте)"
            label-for="contact-notes"
            class="mb-0"
          >
            <b-form-textarea
              id="contact-notes"
              v-model.trim="contact.notes"
              placeholder="Введите комментарий"
            />
            <small
              v-if="!!get(validation, 'notes[0]')"
              class="text-danger"
            >
              {{ get(validation, 'notes[0]') }}
            </small>
          </b-form-group>
        </b-col>
      </template>

    </b-form-row>
    <b-form-row v-if="!isAlreadyExistsPerson">
      <b-col
        cols="12"
        class="mt-1 text-right"
      >
        <template v-if="showMode === 'show'">
          <b-button
            variant="flat-danger"
            class="btn-icon ml-1"
            size="sm"
            @click="detachPerson"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <b-button
            variant="flat-primary"
            class="btn-icon ml-1"
            size="sm"
            @click="onEditMode"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
        </template>

        <b-button
          v-if="showMode === 'edit'"
          size="sm"
          variant="outline-primary"
          class="ml-1"
          @click="updatePerson"
        >
          Сохранить контакт
        </b-button>

        <b-button
          v-if="showMode === 'create'"
          size="sm"
          variant="outline-primary"
          class="ml-1"
          @click="createPerson"
        >
          Создать контакт
        </b-button>
      </b-col>
    </b-form-row>
    <div
      v-else
      class="contact-select__exist-person"
    >
      <b-alert
        variant="primary"
        show
      >
        <h4 class="alert-heading">
          Внимание!
        </h4>
        <div class="alert-body">
          Пользователь с таким номером уже существует
          <hr>
          <strong>{{ tempPerson.surname }} {{ tempPerson.name }} {{ tempPerson.patronymic }}</strong><br>
          <strong>Телефон:</strong> {{ tempPerson.phone }}
          <div v-if="tempPerson.notes">
            <strong>Комментарий</strong>
            <div style="white-space: pre-line;">
              {{ tempPerson.notes }}
            </div>
          </div>
          <div class="text-right mt-2">
            <b-button
              size="sm"
              variant="primary"
              class=""
              @click="selectAlreadyExistPerson"
            >
              Выбрать
            </b-button>
          </div>

        </div>
      </b-alert>
    </div>
  </b-overlay>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRow,
  BCol,
  BButton,
  BOverlay,
  BCard,
  BTableSimple,
  BTbody,
  BTh,
  BTd,
  BTr,
  BAlert,
} from 'bootstrap-vue'
import {
  ref, reactive, watch, computed, getCurrentInstance,
} from 'vue'
import get from 'lodash/get'
import APhoneInput from '@/components/form/APhoneInput.vue'
import {
  getPerson as getPersonApi, createPerson as createPersonApi, updatePerson as updatePersonApi, getPersons,
} from '@/services/main-api/contacts/persons'
import useValidation from '@/composables/useValidation'

export default {
  name: 'ContactSelect',
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRow,
    BCol,
    BButton,
    APhoneInput,
    BOverlay,
    BCard,
    BTableSimple,
    BTbody,
    BTh,
    BTd,
    BTr,
    BAlert,
  },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props, { emit }) {
    const contact = reactive({
      id: null,
      phone: '',
      name: '',
      surname: '',
      patronymic: '',
      notes: '',
    })
    const instance = getCurrentInstance().proxy
    const key = ref(1)
    const loading = ref(false)
    const personLoading = ref(false)
    const mode = ref('show')

    const tempPerson = ref(null)

    const {
      validation,
      error_message,
      clearValidation,
      catchValidation,
    } = useValidation()

    const createPerson = async () => {
      clearValidation()
      loading.value = true
      try {
        const res = await createPersonApi({
          company_id: instance.$store.getters['workingMode/selected_company_id'],
          phone: contact.phone,
          name: contact.name,
          surname: contact.surname,
          patronymic: contact.patronymic,
          notes: contact.notes,
        })
        Object.keys(contact).forEach(key => {
          contact[key] = res.data.data[key]
        })
        emit('input', res.data.data.id)
        mode.value = 'show'
      } catch (e) {
        console.log(e)
        catchValidation(e)
      } finally {
        loading.value = false
      }
    }

    const updatePerson = async () => {
      clearValidation()
      loading.value = true
      try {
        const res = await updatePersonApi({
          id: contact.id,
          phone: contact.phone,
          name: contact.name,
          surname: contact.surname,
          patronymic: contact.patronymic,
          notes: contact.notes,
        })
        Object.keys(contact).forEach(key4 => {
          contact[key4] = res.data.data[key4]
        })
        mode.value = 'show'
      } catch (e) {
        catchValidation(e)
      } finally {
        loading.value = false
      }
    }

    const getPersonBy = async id => {
      if (!id || get(contact, 'id') === id) {
        return
      }
      personLoading.value = true
      try {
        const res = await getPersonApi({
          id,
        })
        Object.keys(contact).forEach(key3 => {
          contact[key3] = res.data.data[key3]
        })
      } catch (e) {
        // ...
      } finally {
        key.value += 1
        personLoading.value = false
      }
    }

    const getTempPersonByPhone = async phone => {
      if (phone.length < 12) {
        return
      }

      try {
        const res = await getPersons({
          company: instance.$store.getters['workingMode/selected_company_id'],
          perPage: 1,
          page: 1,
          searchFields: 'phone:=',
          search: `phone:${phone}`,
        })

        tempPerson.value = get(res, 'data.data[0]')
      } catch (e) {
        tempPerson.value = null
      }
    }

    const detachPerson = () => {
      emit('input', null)
      contact.id = null
      contact.name = ''
      contact.surname = ''
      contact.patronymic = ''
      contact.notes = ''
      contact.phone = ''
      mode.value = 'show'
    }

    const onEditMode = () => {
      mode.value = 'edit'
    }

    watch(() => props.value, newValue => {
      getPersonBy(newValue)
    })

    const showMode = computed(() => {
      if (contact.id === null) {
        return 'create'
      } if (mode.value === 'edit' && !!contact.id) {
        return 'edit'
      }

      return 'show'
    })

    watch(() => contact.phone, nevValue => {
      if (showMode.value === 'create' && nevValue.length === 12) {
        getTempPersonByPhone(nevValue)
      }
    })

    const isAlreadyExistsPerson = computed(() => showMode.value === 'create' && contact.phone.length === 12 && contact.id === null && !!tempPerson.value)

    const selectAlreadyExistPerson = () => {
      Object.keys(tempPerson.value).forEach(key2 => {
        contact[key2] = tempPerson.value[key2]
      })
      emit('input', tempPerson.value.id)
      tempPerson.value = null
      mode.value = 'show'
    }

    return {
      key,
      loading,
      validation,
      error_message,
      contact,
      personLoading,
      tempPerson,

      createPerson,
      updatePerson,
      detachPerson,
      onEditMode,
      mode,
      showMode,
      isAlreadyExistsPerson,
      selectAlreadyExistPerson,

      get,
    }
  },
}
</script>

<style scoped lang="scss">
// Core variables and mixins
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.contact-select{
  &__info{
    &__item{
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
</style>
