<script setup>
import { defineProps, defineEmits, ref } from 'vue'

import { BAlert } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { getExternalStats } from '@/services/main-api/shop/orders/orders'

const props = defineProps({
  stats: {
    type: Object,
    default: null,
  },
  orderId: {
    type: [Number, String],
    default: null,
  },
})

const emits = defineEmits([
  'update:stats',
])

const externalOrderStatsCard = ref(null)

const errorMessage = ref(null)

const getStats = async () => {
  errorMessage.value = null
  try {
    // loading.value = true
    const res = await getExternalStats({
      id: props.orderId,
    })
    emits('update:stats', res.data.data)
  } catch (e) {
    errorMessage.value = 'Ошибка получения отчета, попробуйте позже'
  } finally {
    externalOrderStatsCard.value.showLoading = false
  }
}
</script>

<template>
  <b-card-actions
    ref="externalOrderStatsCard"
    title="Отчет"
    action-collapse
    collapsed
    action-refresh
    @refresh="getStats"
  >
    <b-alert
      v-if="errorMessage"
      variant="danger"
      show
      class="mb-0"
    >
      <div class="alert-body">
        {{ errorMessage }}
      </div>
    </b-alert>

    <div v-else-if="stats">
      <vue-json-pretty
        :data="stats"
      />
    </div>

    <b-alert
      v-else
      variant="info"
      show
      class="mb-0"
    >
      <div class="alert-body">
        Нет данных отчета. Нажмите обновить
      </div>
    </b-alert>

  </b-card-actions>
</template>

<style scoped lang="scss">

</style>
