import axiosIns from '@/libs/axios'

export function createSourceOrder({
  order_id,
  type,
  number,
}) {
  return axiosIns.post('/shop/source-orders', {
    order_id,
    type,
    number,
  })
}

export function updateSourceOrder({
  id,
  number,
}) {
  return axiosIns.patch(`/shop/source-orders/${id}`, {
    number,
  })
}

export function deleteSourceOrder(id) {
  return axiosIns.delete(`/shop/source-orders/${id}`)
}

export default {
  createSourceOrder,
  updateSourceOrder,
  deleteSourceOrder,
}
